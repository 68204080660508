import { nbspify } from "./nbspify.js";

export function nbspifyHtml(html: HTMLElement | string): string {
	let node: HTMLElement;
	if (typeof html === "string") {
		node = document.createElement("div");
		node.innerHTML = html;
	} else {
		node = html;
	}
	for (const child of node.childNodes) {
		if (child.nodeType === 3) {
			if (child.nodeValue) {
				child.nodeValue = nbspify(child.nodeValue);
			}
		} else if (child.nodeType === 1) {
			const node = child as HTMLElement;
			node.innerHTML = nbspifyHtml(node);
		}
	}
	return node.innerHTML;
}
